* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-synthesis: none;
}

body,
html {
  background: #1c1240;
}

.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
  position: relative;
}

.landing-page__box {
  background: rgba(64, 52, 109, 0.4);
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 600px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 30px;
  z-index: 1;
  backdrop-filter: blur(10px);
}

.landing-page__content {
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 7px;
  transition: 0.2s ease-in-out;
  border: 4px dotted rgb(109, 96, 158);
  text-decoration: none;
  background: rgba(64, 52, 109, 0.5);
}

.landing-page__content:hover {
  background: rgb(64, 52, 109);
}

.landing-page__header {
  display: flex;
  flex-flow: column nowrap;
  gap: 7px;
  align-items: center;
}

.landing-page__logo {
  width: 175px;
  height: auto;
}

.landing-page__content p {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 85px;
  height: auto;
  color: #f5f5f5;
  width: 100%;
  font-size: 20px;
}

.landing-page__content svg {
  width: 85px;
  height: auto;
}

.landing-page__title {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  color: #f5f5f5;
}

/* circle ripple */
.landing-page__circles {
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.landing-page__small {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #1c1240;
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
  opacity: 0.4;
}

.landing-page__medium {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #1c1240;
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
  opacity: 0.3;
}

.landing-page__large {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #1c1240;
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
  opacity: 0.25;
}

.landing-page__xlarge {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #1c1240;
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
  opacity: 0.15;
}

.landing-page__xxlarge {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #1c1240;
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
  opacity: 0.05;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}
